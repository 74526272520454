import React from "react";
import styled from "styled-components";

import "./App.css";

const AppContent = styled.div`
  height: 100vh;

  h1 {
    font-size: 50px;
    background-color: #1f1f1f;
    color: white;
    margin: 0;
  }

  a {
    display: inline-block;
    background-color: #1f1f1f;
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding: 4px;
    margin: 10px 4px;
  }
`;

function App() {
  return (
    <div className="App">
      <AppContent>
        <h1>HALF POWER</h1>
        <a
          href="https://open.spotify.com/track/6LVZQJUFBZZigjXkbmnW0F?si=1K5ELcNcT6iHAXerBounUw"
          target="_blank"
          rel="noopener noreferrer"
        >
          New single, "The Wreckage", available NOW
        </a>
      </AppContent>
    </div>
  );
}

export default App;
